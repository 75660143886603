// @ts-check
import React from "react";
import {
  createTheme,
  CssBaseline,
  Grid,
  Link,
  ThemeProvider,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { PageHeader } from "./PageHeader";
import { PageContent } from "./PageContent";
import "./App.css";

const DocsCard = (props) => {
  const { title, description, url, iconPath, iconAlt } = props;

  // Check if the current protocol is not HTTPS, then redirect
  if (process.env.NODE_ENV === 'production') {
    if (window.location.protocol !== 'https:') {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    }
  } else {console.log('Not in production mode, skipping HTTPS redirect')}

  return (
    <div className="Docs-card">
      <Link href={url} className={"Docs-card-link"}>
        <Grid container direction="row" alignItems={"center"}>
          <Grid item>
            {iconPath ? (
              <img src={iconPath} alt={iconAlt} className="Docs-tee-icon" />
            ) : (
              <SpeakerNotesIcon
                style={{
                  fontSize: 68,
                  color: "#bbb",
                  width: 86,
                  marginRight: 16,
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h2">{title}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{description}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ flex: 1 }} />
          <Grid item>
            <ChevronRightIcon style={{ fontSize: 48 }} />
          </Grid>
        </Grid>
      </Link>
    </div>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#258465",
    },
    secondary: {
      main: "rgb(15, 66, 65)",
    },
  },
  typography: {
    fontFamily: [
      "Roboto Regular",
      "Montserrat Regular",
      "-apple-system",
      "BlinkMacSystemFont",
      "Roboto",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
    ].join(","),
    body1: {
      fontFamily: "Roboto Regular",
      fontSize: 18,
      color: "#4A4A4A",
    },
    body2: {
      fontFamily: "Roboto Regular",
      fontSize: 18,
      color: "#4A4A4A",
    },
    h1: {
      fontFamily: "Montserrat Regular",
      fontSize: 30,
      color: "rgb(15, 66, 65)",
    },
    h2: {
      fontFamily: "Roboto Regular",
      fontSize: 24,
      color: "#258465",
      marginBottom: 8,
    },
    h3: {
      fontFamily: "Montserrat Semi-Bold",
      fontSize: 17,
      color: "#258465",
    },
    overline: {
      fontFamily: "Roboto Regular",
      fontSize: 10,
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <PageHeader />
        <PageContent includeFooter includeDecoration>
          <Grid container direction="column">
            <Grid item textAlign={"center"}>
              <div className="Docs-header">
                <Typography variant="h1" fontSize={48}>
                  Anjuna Seaglass Documentation
                </Typography>
                <p />
                <Typography variant="h2">
                  Browse the Anjuna release notes and documentation.
                </Typography>
              </div>
              <Grid container direction="column" item textAlign={"left"}>
                <DocsCard
                  title={"Release notes"}
                  description={"See what changed in each product release"}
                  url={"https://docs.anjuna.io/release-notes/"}
                />
                <DocsCard
                  title="AWS Nitro Documentation"
                  description="Browse the documentation for the Anjuna Runtime for AWS Nitro Enclaves"
                  iconPath={"/nitro.svg"}
                  iconAlt={"AWS Nitro"}
                  url={"https://docs.anjuna.io/nitro/latest/index.html"}
                />
                <DocsCard
                  title="AMD SEV Documentation"
                  description="Browse the documentation for the Anjuna Runtime for AMD SEV"
                  iconPath={"/sev.svg"}
                  iconAlt={"AMD SEV"}
                  url={"https://docs.anjuna.io/sev/latest/index.html"}
                />
                <DocsCard
                  title={"Anjuna Kubernetes Toolset for AMD SEV Documentation"}
                  description={"Browse the documentation for the Anjuna Kubernetes Toolset for AMD SEV"}
                  iconPath={"/k8s.svg"}
                  iconAlt={"Anjuna Kubernetes Toolset"}
                  url={"https://docs.anjuna.io/k8s-sev/latest/index.html"}
                />
                <DocsCard
                  title="Intel® SGX Documentation"
                  description="Browse the documentation for the Anjuna Runtime for Intel® SGX"
                  iconPath={"/sgx.svg"}
                  iconAlt={"Intel® SGX"}
                  url={"https://docs.anjuna.io/sgx/latest/index.html"}
                />
                <DocsCard
                  title={"Anjuna Policy Manager Documentation"}
                  description={"Browse the documentation for the Anjuna Policy Manager"}
                  iconPath={"/apm.svg"}
                  iconAlt={"Anjuna Policy Manager"}
                  url={"https://docs.anjuna.io/apm/latest/index.html"}
                />

              </Grid>
            </Grid>
          </Grid>
        </PageContent>
      </Grid>
    </ThemeProvider>
  );
};

export default App;

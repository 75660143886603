// @ts-check
import React from "react";
import { Grid } from "@mui/material";

export const PageFooter = () => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className="PageFooter-container"
    ></Grid>
  );
};

import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import anjuna from "./assets/anjuna.svg";

export const PageHeader = () => {
  return (
    <AppBar sx={{ background: "white" }}>
      <Toolbar>
        <img src={anjuna} alt="Anjuna" />
        <div className="flex-spacer" />
      </Toolbar>
    </AppBar>
  );
};

// @ts-check
import React from "react";
import { Fade } from "@mui/material";
import { PageFooter } from "./PageFooter";
import graphic from "./assets/anjuna-logo-graphic.svg";

/**
 *
 * @param {{children:any,application?:boolean,includeFooter?:boolean,includeDecoration?:boolean,noPad?:boolean,style?:{}}} param0
 * @returns
 */
export const PageContent = ({
  children,
  application,
  includeFooter,
  includeDecoration,
  noPad,
  style,
}) => {
  return (
    <div
      className={`PageContent-container ${
        application ? "PageContent-application" : ""
      }`}
      style={{ ...style }}
    >
      {includeDecoration ? (
        <Fade in timeout={300}>
          <div
            className="Dashboard-decorationImage"
            style={{
              backgroundImage: `url(${graphic})`,
            }}
          />
        </Fade>
      ) : null}
      <Fade in timeout={300}>
        <div
          className={`PageContent-content ${
            noPad ? "PageContent-content-noPad" : undefined
          }`}
        >
          <div className="PageContent-body">{children}</div>
          {includeFooter ? (
            <>
              <div className="flex-spacer" />
              <div className="PageContent-footer">
                <PageFooter />
              </div>
            </>
          ) : null}
        </div>
      </Fade>
    </div>
  );
};
